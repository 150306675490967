@charset "UTF-8";
/* CSS Document */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


html {
  padding: 0;
  margin: 0;
  height: 100%;
  color: #666;
  font-family: 'Roboto', sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  font-size: 18px;
}

body,
#root {
  height: 100%;
}

* {
  margin: 0;
}
.normal {
  color: #666;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
}

.smtitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #222;
  font-size: 18px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}
.smthintitle,
.smmidtitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #666;
  font-size: 16px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}
.smthintitle {
  font-weight: 400;
}
.smmidtitle {
  font-weight: 500;
}
.formtitle,
.formtitlesm {
  position: relative;
  z-index: 1;
  font-size: 12px;
  padding: 1px 3px 0 0;
  top: 6px;
  left: 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #222;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  float: left;
  background: #fff;
}
.formtitle {
  top: 6px;
  margin-left: 15px;
}
.formtitlesm {
  top: 0px;
  background: none;
}
.title,
.subpagetitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #222;
  -webkit-font-smoothing: antialiased;
}
.large {
  font-size: 45px;
  line-height: 45px;
  font-family: 'Roboto', sans-serif;
  color: #444;
}
a {
  color: #666;
  text-decoration: underline;
}
a:hover {
  color: #999;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.a {
  color: #222;
  text-decoration: underline;
}
.a:hover {
  text-decoration: none;
  cursor: pointer;
}
.bold {
  font-weight: 700;
}
.u {
  text-decoration: underline;
}
.i {
  font-style: italic;
}
span {
  color: inherit;
}
.red {
  color: #c00;
}
.orange {
  color: #f60;
}
.orangebg {
  background: #f60 !important;
}
.green {
  color: #0c0;
}
.pink {
  color: #f06;
}
.purple {
  color: #606;
}
.purplebg {
  background: #606 !important;
}
.blue {
  color: #06c !important;
}
.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.black {
  color: #000;
}
.white {
  color: #fff !important;
}
.redborder {
  border: 1px solid #c00 !important;
}
.error {
  color: #c00 !important;
}
.forgot {
  float: left;
  padding: 10px 0 0 5px;
}

input[type="button"] {
  border: none;
}
input[type="text"],
input[type="password"] {
  height: 46px;
  border: 1px solid #ccc;
  font-size: 15px;
  color: #666;
  padding-left: 5px;
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box;
  width: 100%;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  border-radius: 30px;
  padding-left: 15px;
}
input[type="text"] .fuzz {
  border: 2px solid #222;
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  margin-right: 10px;
}
input[type="radio"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
}
input[type="radio"]:checked + label span {
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  margin-right: 5px;
}
input[type="checkbox"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
}
input[type="checkbox"]:checked + label span {
}
.searchfield {
  height: 36px;
  font-size: 13px;
  color: #455068;
  padding-left: 30px;
  border: 0px;
  cursor: pointer;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}
textarea {
  border: 1px solid #ccc;
  font-size: 15px;
  color: #666;
  -webkit-font-smoothing: subpixel-antialiased;
  padding: 5px;
  font-weight: 300;
  box-sizing: border-box;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  border-radius: 4px;
}
input[type="text"]:focus,
select:focus,
.inputarea:focus,
textarea:focus {
  outline: none;
  border-color: rgba(0, 102, 204, 0.7);
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 8px rgba(0, 102, 204, 0.7);
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 8px rgba(0, 102, 204, 0.7);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 102, 204, 0.7);
}
.frontinvite {
  width: 100% !important;
  text-align: center !important;
  height: 20px !important;
  background: none !important;
  border: none !important;
  color: #fff !important;
  text-shadow: 0 1px 2px #000 !important;
  font-size: 16px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  font-weight: 500 !important;
}
.inputfieldselect {
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  height: 33px;
  border-radius: 3px;
  font-weight: 300;
}
.inputfilepic {
  width: 100px;
  height: 38px;
  position: relative;
  top: -3px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  z-index: 100;
}
.inputfilelogo {
  width: 100px;
  height: 20px;
  position: relative;
  top: 0px;
  left: 30px;
  opacity: 0.01;
  cursor: pointer;
}

.smrow {
  padding-bottom: 3px;
  min-height: 30px;
  width: 100%;
}
.formitem {
  width: 30%;
  float: left;
  text-align: right;
  margin: 10px 1% 0 0;
  white-space: nowrap;
}
.formleft {
  float: left;
  width: 15%;
  margin-right: 2%;
  padding-top: 8px;
}
.formright {
  float: left;
  width: 68%;
}
.button {
  float: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 1.3;
  font-size: 18px;
  padding: 12px 2% 10px;
  background: #222;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: none;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
}
.button:hover {
  background: #444;
  cursor: pointer;
  opacity: 1;
}
.redbutton {
  background: #222 !important;
}
.redbutton:hover {
  background: #cd7b4a !important;
  cursor: pointer;
}
.bluebutton {
  background: #0172f1 !important;
}
.bluebutton:hover {
  background: #0256b4 !important;
  cursor: pointer;
}
.greenbutton {
  background: #0c0 !important;
}
.greenbutton:hover {
  background: #090 !important;
  cursor: pointer;
}
.smbutton {
  float: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 1.3;
  font-size: 14px;
  padding: 6px 14px 4px;
  background: #333;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: none;
  text-transform: uppercase;
  margin: 5px 5px 0 0;
  border-radius: 30px;
}
.smbutton:hover {
  background: #222;
  color: #fff;
  cursor: pointer;
  opacity: 1;
}
.frontbutton {
  text-align: center;
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 1.3;
  font-size: 22px;
  padding: 7px 0 5px;
  background: #222;
  color: #3f0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: none;
}
.frontbutton:hover {
  background: #3f0;
  cursor: pointer;
  color: #222;
  opacity: 1;
}
.followbutton {
  float: left;
  background: #222;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  font-style: italic;
  padding: 8px 35px 8px 50px;
  position: relative;
  left: -50px;
  top: -4px;
  -webkit-clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
}
.followbutton:hover {
  background: #222;
  cursor: pointer;
  padding: 8px 35px 8px 55px;
}
#followbox {
  float: left;
}
.profilebar {
  padding-left: 1%;
  box-sizing: border-box;
  height: 40px !important;
  position: relative;
  padding-top: 7px;
  top: -20px !important;
}
select {
  border: 1px solid #ccc;
  padding: 0 25px 0 15px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  height: 46px;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  text-indent: 0.01px;
  text-overflow: "";
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: 'Roboto', sans-serif;
  border-radius: 30px;
}
select::-ms-expand {
  display: none;
}
.mydroplist {
  border-radius: 0;
  border: none;
  color: #e92b4f;
}
.css-select {
  -moz-appearance: window;
}
@-moz-document url-prefix() {
  .css-select-moz {
    height: 27px;
    font-size: 13px;
    color: #999;
    border: 1px solid #ccc;
    padding: 7px 25px 0 0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    float: left;
  }
}
.popout {
  display: none;
  position: absolute;
  z-index: 100000;
  top: 0;
  width: 100%;
}
.center {
  position: relative;
  left: 50%;
  width: 0px;
}
.cover {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
  cursor: pointer;
}
.centerbox,
.centerwide {
  padding: 1% 0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  position: relative;
  z-index: 1001;
  box-shadow: 0 0 20px #222;
}
.centerbox {
  background: #fff;
}
.centerwide {
  background: #f1f1f1;
}
.centerclose {
  position: absolute;
  bottom: -24px;
  padding: 3px 0;
  width: 20%;
  margin: 0 40%;
  border-radius: 0 0 3px 3px;
  background: #444;
  left: 0px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.centerclose:hover {
  color: #f60;
}
.fl {
  float: left !important;
}
.tc {
  text-align: center !important;
}
.tl {
  text-align: left !important;
}
.ts {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5) !important;
}

.m20 {
  margin: 20px 0;
}
.m10 {
  margin: 10px 0;
}
.p10 {
  padding: 10px 0;
}
.m5 {
  margin: 5px 0;
}

/*Pass it down*/
div {
  box-sizing: border-box;
}
.fronttop {
  width: 100%;
  position: relative;
  box-shadow: 0 0 20px #222;
}
.subtop {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.search {
  float: left;
  position: relative;
  left: 40px;
}
.search input {
  padding-left: 37px !important;
  border-radius: 50px;
}
.navbar {
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  z-index: 100;
}
.navbar {
  position: fixed;
}

.fff {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.7);
}
.fff .subnav {
  color: #333;
}
.gradient {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
}
.gradient_bottom {
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 );
}
.sitegrad {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.bluebg {
  background: #06c !important;
}
.lightbluebg {
  background: #b7d7f8 !important;
  color: #06c;
}
.whitebg {
  background: #fff !important;
}
.whitebg:hover {
  color: #999 !important;
}
.pinkbg {
  background: #f06 !important;
}
.redbg {
  background: #c00 !important;
}
.greenbg {
  background: #0c3 !important;
}
.navs {
  float: right;
}
.subnav {
  float: left;
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
  position: relative;
}
.subnav:hover {
  cursor: pointer;
}
.subnav .down {
  position: relative;
  right: -14px;
  top: 8px;
  float: right;
  height: 9px;
  width: 10px;
}
.fff .subnav .down,
.subnav .down {
}
.subnav:hover .items {
  display: block;
}
.subnav a {
  font-size: 12px;
  color: #fff;
  position: relative;
  top: 5px;
  font-weight: 600;
}
.subpic {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #333;
  position: relative;
}
.subemail {
  width: 30px;
  height: 30px;
  float: left;
  position: relative;
  top: 7px;
  right: 20px;
}
.subemail:hover {
  cursor: pointer;
  opacity: 0.8;
}
.fff .subemail {
}
.fff .subnav a {
  color: #333;
}
.items {
  top: 100%;
}
.items .line {
  padding: 3px 10px 3px 15px;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
}
.items .line:hover {
  background: #06c;
  color: #fff;
}
.hamburger {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.fff .hamburger,
.hamburger {
}
.demo {
  width: 100px;
  height: 44px;
  font-size: 20px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-weight: 600;
  padding: 10px;
}
.demo:hover {
  background: #39f;
  cursor: pointer;
}
.touchscreen {
  border: 22px solid #000;
  border-radius: 14px;
  position: absolute;
  right: 0;
  z-index: 1;
}
.touchme {
  width: 100%;
  height: 50px;
  position: absolute;
  top: -80px;
}
.catflip {
  position: absolute;
  right: 0;
  z-index: 1;
}
.frontvideo {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.frontvideo:hover {
  opacity: 0.85;
  cursor: pointer;
}
.frontbar {
  width: 100%;
  padding: 0 2%;
  position: relative;
}
.frontcenter {
  text-align: center;
  color: #fff;
  font-weight: 700;
  position: relative;
  z-index: 2;
  white-space: nowrap;
}
.fronticons {
  float: left;
  opacity: 1;
  box-sizing: unset;
}
.fronticons:hover {
  cursor: pointer;
  opacity: 0.8;
  background-size: 85%;
}
.fronticons_blue {
  background-color: #39f !important;
  background-size: 90% !important;
}
.front_museums {
}
.front_libraries {
}
.front_brands {
}
.front_communities {
}
.front_cultural {
}
.front_sports {
}

.demoinput {
  float: left;
  width: 80%;
  height: 50px;
  border: 0;
  padding-left: 45px;
  box-sizing: border-box;
  font-size: 18px;
  color: #666;
}
.demobutton {
  float: left;
  width: 20%;
  height: 50px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding-top: 16px;
  font-weight: 600;
}
.demobutton:hover {
  cursor: pointer;
  background: #39f;
}
.frontone {
  width: 100%;
  position: relative;
  box-shadow: 0 0 20px #222;
}
.fronttwo {
  width: 100%;
  padding: 70px 2%;
  color: #fff;
  font-size: 22px;
  position: relative;
}
.frontthree {
  width: 100%;
  padding: 150px 2%;
  color: #fff;
  font-size: 22px;
  position: relative;
}
.frontthree .front50 {
  float: right;
}
.frontfour {
  width: 100%;
  position: relative;
}
.frontfive {
  width: 100%;
  padding: 50px 2%;
  position: relative;
}
.frontsix {
  padding: 50px 2%;
  width: 100%;
  position: relative;
}
.graybg {
  background-color: #f2f2f2 !important;
}
.medgraybg {
  background-color: #666 !important;
}
.darkgraybg {
  background-color: #333 !important;
}
.ex_type {
  background: #06c;
  padding: 4px 15px;
  font-weight: 700;
  float: left;
  color: #fff;
  margin-top: 10px;
  border-radius: 20px;
}
.ex_type:hover {
  background: #39f;
  cursor: pointer;
}
.footer {
  width: 96%;
  padding: 30px 2%;
  font-size: 16px;
  color: #444;
  background: #fff;
  position: relative;
  box-sizing: unset;
}
.footer a {
  color: #06c;
  font-size: 13px;
  margin-right: 10px;
}
.footer_right {
  float: right;
}
.front50,
.front33,
.footer_left {
  float: left;
}
.front_screens {
  height: 400px;
}
.front_explainer {
  font-size: 18px;
}
.frontcase {
  position: relative;
  z-index: 2;
}
.learnmore {
  float: left;
  margin-top: 20px;
  border: 3px solid #fff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  height: 50px;
  padding: 7px 18px;
}
.learnmore:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.8);
}
.sidegradient {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
}
.front_testimonial {
  border-radius: 100%;
  margin-right: 3%;
}
.front_tile {
  float: left;
  margin: 20px 2%;
}
.subpagetitle {
  position: absolute;
  bottom: 10px;
  left: 2%;
  color: #fff;
  z-index: 2;
}
.fullvideo {
  width: 100%;
  padding-top: 35%;
  position: relative;
  margin: 30px 0;
}
.fullvideo:hover {
  cursor: pointer;
}
.videoplay {
  position: absolute;
  width: 20%;
  padding-top: 20%;
  top: 25%;
  left: 40%;
  opacity: 0.7;
}
.audioplay {
  width: 150px;
  height: 53px;
  margin-right: 10px;
  float: left;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.audioplay:hover {
  cursor: pointer;
  background-color: #666;
}
.fullvideo:hover .videoplay {
  opacity: 1;
}
.demotitle {
  position: absolute;
  top: -24px;
  text-align: left;
}

/*Demo page*/
.demo_page {
  overflow-y: hidden;
  position: relative;
}
.demo_page:hover {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.demo_page:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.demo_item {
  width: 100%;
  position: relative;
  z-index: 0;
}
.demo_item:hover {
  z-index: 1;
}
.demo_title {
  font-size: 26px !important;
  text-shadow: 0 2px 4px #222;
  bottom: 10px;
}
.demo_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
  z-index: 0;
}
.demo_subtop {
  font-size: 16px;
  width: 100%;
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.demo_subtop div {
  padding: 10px 2%;
  font-weight: 600;
  border-right: 1px solid #fff;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
}
.demo_subtop div:hover {
  cursor: pointer;
  opacity: 0.9;
}
.demotop {
  width: 100%;
  padding: 10px 1%;
  position: relative;
}
.demologo {
  float: left;
}
.demologo:hover {
  cursor: pointer;
}
.demosearch {
  float: left;
  position: relative;
  top: 27px;
  left: 40px;
}
.demosearch input,
.map_search input {
  padding-left: 37px;
  font-family: 'Roboto', sans-serif;
}
.demosearch input .form-title {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0px;
}
.demo_categories {
  position: absolute;
  z-index: 100;
  top: 40px;
}
.demo_catlist {
  padding: 10px;
  width: 100%;
  font-weight: 600;
  background: #f1f1f1;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
}
.demo_catlist:hover {
  background: #fff;
  cursor: pointer;
}
.dateicon,
.locationicon,
.phoneicon {
  float: left;
  margin-right: 15px;
  font-weight: 600;
  font-size: 15px;
  height: 22px;
}
.dateicon {
  padding-left: 28px;
}
.locationicon {
  padding-left: 22px;
  position: relative;
  top: -3px;
}
.phoneicon {
  padding-left: 25px;
  position: relative;
  top: -3px;
}
.locationicon a {
  font-family: 'Roboto', sans-serif;
}
.next {
  position: fixed;
  right: 0;
  top: 45%;
  padding-top: 10%;
  width: 8%;
}
.category_top {
  width: 100%;
  position: relative;
  z-index: 0;
  height: 300px;
}
.cat_filters {
  float: left;
  width: 100%;
  padding: 5px 2%;
  background: #f1f1f1;
}
.cat_filters div {
  float: left;
  font-weight: 600;
  margin: 5px 2% 5px 0;
  padding: 3px 10px;
  font-size: 16px;
}
.cat_filters div:hover {
  cursor: pointer;
  text-decoration: underline;
}
.category_title {
  font-size: 32px !important;
  text-shadow: 0 2px 4px #222;
  bottom: 40px;
}
.category_description {
  position: absolute;
  z-index: 1;
  text-shadow: 0 1px 2px #222;
  bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 99%;
}
.category_title,
.demo_title,
.story_title,
.story_tile_title {
  position: absolute;
  z-index: 1;
}
.admin_editor {
  position: relative;
  top: 100%;
  z-index: 1;
}
.admin_editor div {
  letter-spacing: 0px;
}
.category_button {
  color: #06c;
  padding: 8px 15px 6px;
  margin: 0 5px 5px 0;
  border-radius: 30px;
}
.category_button:hover {
  background: #06c;
  cursor: pointer;
  color: #fff;
}
.filter_button {
  color: #666;
  padding: 4px 20px;
  float: left;
  text-transform: unset;
  border-radius: 0;
  margin-right: 1px;
}
.filter_button:hover {
  background: #999;
  color: #fff;
  cursor: pointer;
}
.filter_button_right {
  border-radius: 0 30px 30px 0;
}
.filter_button_left {
  border-radius: 30px 0 0 30px;
}
.story_tile {
  margin: 3% 1.5%;
  position: relative;
  float: left;
}
.story_tile:hover {
  cursor: pointer;
}
.story_active {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 600;
}
.story_tile_title {
  display: none;
  bottom: 10px;
  white-space: nowrap;
  max-width: 93%;
  overflow: hidden;
  float: left;
}
.story_tile:hover .story_tile_title {
  display: block;
}
.story_title {
  text-shadow: 0 2px 4px #222;
  bottom: 10px;
}
.story_title_bar {
  padding-right: 16% !important;
}
.story_top {
  position: relative;
  min-height: 300px;
}
.story_caption {
  position: absolute;
  bottom: 1%;
  right: 1%;
  color: #fff;
  z-index: 1;
}
.story_pics {
  width: 100%;
  margin: 15px 0;
}
.story_pic_main {
  float: left;
  width: 30%;
  padding-top: 30%;
  margin-right: 3%;
  position: relative;
}
.story_pic {
  float: left;
  width: 25%;
  padding-top: 25%;
  margin: 0 2% 10px 0;
}
.story_pic_manage {
  float: right;
  width: 73%;
  position: relative;
}
.story_desc {
  position: relative;
}
.story_qr {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 123px;
  height: 123px;
}
.story_qr img {
  margin: 23px 0 0 23px;
}
.subcategories {
  float: left;
  width: 270px;
  margin: 7px 0;
}
.uploadmedia {
  float: left;
  width: 150px;
  height: 44px;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.uploadmedia:hover {
  cursor: pointer;
  border: 1px solid #999;
}
.uploadbutton,
.uploadbutton2 {
  width: 150px;
  float: left;
  height: 44px;
  padding: 5px 20px;
  border: 1px solid #ccc;
  margin-right: 0;
}
.uploadbutton {
  border-right: 0;
  border-top: 0;
}
.uploadbutton2 {
  border-bottom: 0;
}
.uploadleft {
  border-radius: 0 0 0 5px;
}
.uploadright {
  border-radius: 0 0 5px 0;
  border-right: 1px solid #ccc;
}
.uploadbutton:hover,
.uploadbutton2:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
.uploadphoto {
}
.uploadaudio {
}
.uploadvideo {
}
.uploadyoutube {
}
.uploadtext {
}
.uploadphoto:hover {
}
.uploadaudio:hover {
}
.uploadvideo:hover {
}
.uploadyoutube:hover {
}
.uploadtext:hover {
}
.formuploadbox {
  padding: 1%;
  background: #f1f1f1;
  margin: 20px 0;
  border: 1px solid #ccc;
}
#uploaderz {
  margin-bottom: 30px;
}

.newuploadbutton {
  width: 120px;
  float: left;
  height: 168px;
  padding: 5px 20px;
  margin: 10px 2%;
}
.newuploadbutton:hover {
  cursor: pointer;
}
.newuploadphoto {
}
.newuploadaudio {
}
.newuploadvideo {
}
.newuploadyoutube {
}
.newuploadtext {
}
.newuploaddropbox {
}

.assetsearch {
  display: none;
  width: 100%;
  position: absolute;
  top: 100%;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  z-index: 2;
  left: 0;
}
.draft {
  border: 1px solid #c00 !important;
  box-shadow: 0 0 18px #c00 !important;
  color: #c00 !important;
  font-weight: 600;
}
.published {
  border: 1px solid #0c3 !important;
  box-shadow: 0 0 18px #0c3 !important;
  color: #0c3 !important;
  font-weight: 600;
}
.delete {
  border: 1px solid #000 !important;
  box-shadow: 0 0 18px #000 !important;
  color: #000 !important;
  font-weight: 600;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.asset_story {
  background: #fff;
  padding: 3px 18px 2px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #666;
  font-weight: 700;
  font-size: 14px;
  border-radius: 50px;
  z-index: 3;
}
.asset_story:hover {
  background: #3c0;
  color: #fff;
  cursor: pointer;
}
.asset_story_on {
  background: #3c0;
  color: #fff;
}
.addbottomstory {
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  padding: 10px 2%;
  background: #06c;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  z-index: 4;
  overflow-y: scroll;
}
.bottomstorypic {
  float: left;
  width: 80px;
  height: 80px;
  margin: 0.5%;
  position: relative;
}
.bottomstorybutton,
.bottomstorycancel {
  position: fixed;
  bottom: 110px;
  z-index: 5;
  border-radius: 50px;
}
.bottomstorybutton {
  right: 10px;
}
.bottomstorycancel {
  right: 200px;
}
.asset_cancel {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.asset_cancel:hover {
  background-color: rgba(0, 0, 0, 1);
  cursor: pointer;
  width: 26px;
  height: 26px;
  right: 2px;
  top: 2px;
}

.photo_icon {
  width: 30px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.video_play {
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.story_old_item {
  padding: 30px 0 40px;
  border-bottom: 1px solid #ccc;
}
.story_old_item:hover {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.story_old_item:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.story_old_item textarea {
  font-size: 18px;
  line-height: 1.5;
  border-radius: 10px;
  min-height: 400px;
}
.buildstorytop {
  padding: 15px 0;
  box-sizing: unset;
  margin-bottom: 20px;
}
.roundform {
  border-radius: 50px;
  padding-left: 10px !important;
}
.roundsquadform {
  border-radius: 8px;
}
.story_old_cat {
  float: left;
  margin: 0 10px 10px 0;
  border-radius: 50px;
  font-size: 15px;
  padding: 7px 16px;
}
.story_old_sub {
  float: left;
  margin: 5px 10px 10px 0;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 13px;
  background: #f1f1f1;
  color: #666;
}
.story_old_sub:hover {
  cursor: pointer;
  background: #ccc;
}
.story_old_sub_on {
  color: #fff !important;
}
.story_old_sub_on:hover {
  background: #333;
}
.story_old_tags {
  float: left;
  margin: 5px 10px 0 0;
  border-radius: 30px;
  color: #444;
  padding: 5px 30px 5px 10px;
}
.story_old_tags:hover {
  opacity: 0.5;
  cursor: pointer;
}
.savebutton {
  width: 80px;
  height: 46px;
  position: relative;
  z-index: 0;
  left: -28px;
  float: left;
  border-radius: 10px;
}
.savebutton:hover {
  background-color: #ccc;
  cursor: pointer;
}
.storybutton {
  border-radius: 8px;
}
.storysmbutton {
  border-radius: 0 0 8px 8px;
  position: relative;
  top: -5px;
  left: 10px;
}
.storyeditbottom {
  position: relative;
  top: 100%;
}
.storyenhance,
.storydelete {
  float: left;
  margin-right: 5px;
}
.storyenhance {
  border-radius: 4px 4px 0 0;
  position: absolute;
  top: -33px;
  right: -5px;
  padding-left: 28px;
}
.storyenhance:hover {
  cursor: pointer;
}
.storydelete {
  background: #c00;
  border-radius: 4px;
}
.storytext {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  background: #fff;
  padding: 10px 2%;
  border-radius: 4px;
}
.storytext:hover {
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
#storystatus,
#storytemplate,
#categories,
#platforms {
}
.grayblock {
  padding: 0 10px 10px 10px;
  background: #f1f1f1;
  border: 1px solid #ccc;
  margin: 40px 0;
  border-radius: 10px;
  position: relative;
}
.fifty {
  float: left;
  padding: 10px 1.5%;
}
.uploader {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #0c3;
  z-index: 1000000000;
}

.slide_left {
  width: 50%;
  float: left;
  position: relative;
  z-index: 2;
}
.slide_left:hover .left_arrow,
.slide_left:hover .right_arrow {
  display: block;
}
.left_arrow,
.right_arrow {
  display: none;
  width: 100%;
  position: absolute;
  min-height: 250px;
}
.right_arrow {
}
.left_arrow {
}
#whitetop {
  min-height: 900px;
}
.platform_logo {
  width: 75px;
  height: 75px;
  float: left;
  margin-top: 10px;
}
.platform_logo:hover {
  cursor: pointer;
  opacity: 0.8;
}
.platform_left {
  margin-right: 1%;
}
.platform_upload:hover {
  cursor: pointer;
}
.platform_screen {
  float: left;
  width: 200px;
  height: 200px;
  margin: 0 2% 10px;
}
.platform_screen:hover {
  cursor: pointer;
  opacity: 0.8;
}
.solutions_right {
  position: relative;
  background: #fff;
  padding: 10px 3%;
  border-top: 5px solid #06c;
}
.platform_tile {
  float: left;
  width: 200px;
  max-height: 300px;
  min-height: 300px;
  margin: 0 2% 50px 0;
  overflow: hidden;
}
.platform_tile:hover {
  cursor: pointer;
  opacity: 0.9;
}
.platform_tile_logo {
  width: 100%;
  padding-top: 100%;
}
.platform_tile_logo:hover {
  cursor: pointer;
}
.platform_tile_name {
  text-align: center;
  margin-top: 10px;
}
.add_new_platform {
  box-shadow: inset 0 0 100px #ccc;
}
.add_new_platform:hover {
  box-shadow: inset 0 0 100px #39f;
  cursor: pointer;
}
.platform_users {
  position: absolute;
  right: 0;
  bottom: 0;
}
.platform_user {
  width: 50px;
  height: 50px;
  float: left;
}
.platform_user:hover {
  cursor: pointer;
}
.adminarrow {
  float: right;
  width: 13px;
  height: 18px;
  margin-left: 10px;
}
.adminbox {
  display: none;
  width: 150px;
  position: absolute;
  top: 32px;
  left: 14px;
  z-index: 10;
  background: #fff;
}
.adminnav {
  position: relative;
}
.adminnav:hover {
  cursor: pointer;
  color: #666;
}
.adminnav:hover .adminbox {
  display: block;
}
.adminline {
  width: 100%;
  padding: 4px 6px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  text-transform: none;
}
.adminline:hover {
  cursor: pointer;
  background: #f1f1f1;
}
.place_logo {
  position: relative;
  float: left;
  z-index: 1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.place_box {
  float: left;
  padding: 5px 10px;
  margin: 5px 5px 0 0;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #ccc;
  float: left;
}
.place_box:hover {
  cursor: pointer;
  background: #ccc;
}
.map_search {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
.mapphoto {
  width: 343px;
  padding-top: 80%;
  position: absolute;
  top: -4%;
  left: -5%;
  border-radius: 5px 5px 0 0;
}
.mapphoto:hover {
  cursor: pointer;
  opacity: 0.95;
}
.mapright {
  margin-top: 5px;
  width: 290px;
  padding-top: 78%;
}
.maptitle {
  font-weight: 600;
  font-size: 18px;
}
.maplink {
  font-weight: 600;
  font-size: 14px;
  padding: 6px 10px;
  border-radius: 50px;
  margin-top: 8px;
  float: left;
  font-family: 'Roboto', sans-serif;
}
.maplink:hover {
  cursor: pointer;
  background: #f1f1f1;
}
.gm-style-iw {
  width: 300px !important;
}
.gm-style .gm-style-iw {
  overflow: unset;
}
.gm-ui-hover-effect {
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 100%;
  right: 6px !important;
  width: 33px !important;
}
.gm-ui-hover-effect img {
  position: absolute;
  top: 0;
  left: -2px;
}
.visitor_media {
  float: left;
  width: 160px;
  height: 120px;
  margin: 5px 5px 0 0;
}
.screen {
  width: 100%;
  min-height: 100%;
  position: relative;
}
.screen_logo {
  width: 40%;
  margin: 0 30% 4%;
  padding: 10% 0;
}
.screen_box {
  width: 50%;
  margin: 0 25%;
}
.screen_button {
  width: 100%;
  border: 2px solid #fff;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  margin: 3% 0;
  font-size: 22px;
  font-weight: 700;
  padding: 5px 0;
  text-align: center;
}
.screen_button:hover {
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.close:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}


/*Admin Area */
.old_media {
  width: 100%;
  padding: 20px 30%;
  background: #f1f1f1;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.old_video,
.old_audio,
.old_text,
.old_photo {
  float: left;
}
.old_media div:hover {
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.old_video {
}
.old_audio {
}
.old_text {
}
.old_photo {
}
.old_formtitle {
  font-size: 20px;
  width: 100%;
  margin: 10px 0 2px;
  font-weight: 600;
}

@media (min-width: 1px) {
  .mobilehide {
    display: none;
  }
  .mobileshow {
    display: block;
  }

  .fronttop,
  .navbar {
    height: 70px;
  }
  .navs {
    position: absolute;
    background: #fff;
    width: 60%;
    height: 100%;
    top: 0;
    right: 0;
  }
  .sitegrad {
    height: 200px;
  }
  .frontcenter {
    width: 96%;
    margin-left: 2%;
    font-size: 33px;
    top: 80px;
  }
  .frontbar {
    z-index: 2;
  }
  .frontvideo {
  }
  .touchscreen {
    display: none;
  }
  .catflip {
    width: 100%;
    height: 410px;
    margin: unset;
    bottom: -60px;
    z-index: 1;
  }

  .fronticons {
    width: 23%;
    height: 80px;
    padding: 20px 5%;
  }

  .title {
    font-size: 32px;
  }
  .front_tile {
    width: 46%;
    padding-top: 25%;
  }
  .frontsix {
    width: 80%;
    margin: 10px 10%;
  }
  .centerbox,
  .centerwide {
    width: 340px;
    left: -170px;
  }
  .page_left,
  .page_right {
    float: left;
    width: 100%;
    margin-top: 30px;
  }
  .subpagetitle {
    font-size: 28px;
    border-bottom: 6px solid #06c;
  }
  .frontone {
    padding: 0;
    margin-top: 0px;
  }
  .front50 {
    width: 100%;
    padding: 0 5%;
  }
  .front_explainer {
    padding: 20px 5%;
    border-top: 2px solid #333;
  }
  .front_screens {
    margin-top: 40px;
  }
  .ex_type {
    font-size: 12px;
    margin-right: 5px;
  }
  .front33 {
    width: 100%;
    margin: 15px 1.5%;
    padding: 0 5%;
  }
  .front_testimonial {
    float: left;
    width: 90%;
    padding-top: 90%;
    margin-left: 5%;
  }
  .frontfour {
    padding: 100px 5%;
  }
  .items .line {
    border-top: none;
    color: #333;
  }
  .items {
    display: block;
    background: none;
    position: relative;
    width: 100%;
  }
  .admin_userline {
    left: 50px;
    top: -20px;
  }
  .subpic {
    top: 0px;
  }
  .subnav {
    padding-right: 0;
  }
  .subnav .down {
    display: none;
  }
  .demo {
    margin: 10px 0;
    float: left;
  }
  .cmp_banner {
    height: 90px;
  }
  .cmp_logo {
    height: 150px;
  }
  .cmp_devices {
    height: 300px;
  }
  .story_tile {
    width: 97%;
    padding-top: 97%;
  }
  .small_story {
    padding: 11.5%;
    margin: 1%;
    float: left;
  }
  .demo_bottom {
    padding-top: 30%;
  }
  .demo_item {
    padding: 77% 5% 2% 0;
  }
  .category_title,
  .demo_title,
  .category_description,
  .story_title,
  .story_tile_title {
    margin-left: 3%;
  }
  .story_title {
    bottom: 30px;
  }
  .story_content,
  .story_desc {
    padding: 15px 5%;
  }
  .subemail {
    display: none;
  }
  .solutions_right {
    top: 0;
  }
  .solutions_right .smtitle {
    color: #06c;
  }
  .story_qr {
    width: 73px;
    height: 73px;
  }
  .story_qr img {
    width: 50px;
    height: 50px;
  }
  .demotop {
    height: 100px;
  }
  .demologo {
    width: 80px;
    height: 80px;
  }
  .demo_mapframe {
    height: 300px;
  }
  .place_logo {
    padding: 7.5%;
    top: -10px;
    z-index: 1;
    border: 3px solid #fff;
    margin-right: 2%;
  }
  .place_title_bar {
    padding: 14px 0 10px 5% !important;
  }
  .place_desc {
    padding: 5px 5%;
  }
  .search {
    top: 12px;
  }
  .screen_logo {
    padding: 16% 0;
  }
  .old_media {
    padding: 20px 10%;
  }
  .old_video,
  .old_audio,
  .old_text,
  .old_photo {
    width: 50%;
    padding-top: 50%;
  }
  .fifty {
    width: 100%;
  }
}
@media (min-width: 550px) {
  .story_tile {
    width: 47%;
    padding-top: 47%;
  }
  .demo_item {
    padding: 31% 5% 2% 0;
  }
  .fronticons {
    width: 14%;
    height: 120px;
    padding: 20px 1%;
  }
  .fronttop,
  .subtop {
    height: 500px;
  }
  .demo_mapframe {
    height: 400px;
  }
  .place_title_bar {
    padding: 14px 0 10px 3% !important;
  }
  .place_desc {
    padding: 5px 5% 5px 18%;
  }
  .screen_logo {
    padding: 14% 0;
  }
}
@media (min-width: 860px) {
  .mobilehide {
    display: block;
  }
  .mobileshow {
    display: none;
  }

  .fff {
  }

  .frontbar {
    z-index: 0;
  }
  .navbar {
    height: 110px;
  }
  .navs {
    float: right;
    margin: 30px 10px 0 0;
    position: relative;
    top: unset;
    right: unset;
    background: unset;
    width: unset;
    height: unset;
  }
  .subtop {
    height: 400px;
  }
  .subpic {
    top: -12px;
  }
  .subemail {
    display: block;
  }
  .sitegrad {
    height: 500px;
  }
  .frontcenter {
    width: 45%;
    margin-left: 5%;
    font-size: 40px;
    top: 200px;
  }
  .frontvideo {
  }
  .touchscreen {
    display: block;
    width: 474px;
    height: 624px;
    margin-right: 3%;
    top: 100px;
  }
  .catflip {
    display: block;
    width: 350px;
    height: 500px;
    margin-right: 3%;
    bottom: 0px;
    z-index: 3;
  }
  #touchframe {
    width: 430px;
    height: 580px;
  }

  .fronticons {
    width: 12%;
    height: 120px;
    padding: 20px;
  }

  .title {
    font-size: 36px;
  }
  .front_tile {
    width: 29%;
    padding-top: 12%;
  }
  .frontsix {
    width: 60%;
    margin: 10px 20%;
  }
  .centerbox {
    width: 500px;
    left: -250px;
  }
  .centerwide {
    width: 760px;
    left: -380px;
  }
  .page_left {
    width: 60%;
    padding-right: 3%;
  }
  .page_right {
    width: 40%;
  }
  .subpagetitle {
    font-size: 34px;
    border-bottom: 10px solid #06c;
  }
  .frontone {
    padding: 70px 2% 0;
  }
  .front50 {
    width: 50%;
    padding: unset;
  }
  .front_explainer {
    padding-top: 80px;
    border-top: none;
  }
  .front_screens {
    margin-top: 0px;
  }
  .ex_type {
    font-size: 16px;
    margin-right: 10px;
  }
  .front33 {
    width: 30.33%;
    margin: 0 1.5%;
    padding: 0;
  }
  .front_testimonial {
    float: right;
    width: 300px;
    padding-top: 300px;
  }
  .frontfour {
    padding: 100px 10%;
  }
  .items .line {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  .items {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 150px;
  }
  .admin_userline {
    left: 80px;
    top: 30px;
  }
  .subnav {
    padding-right: 70px;
  }
  .subnav .down {
    display: block;
  }
  .demo {
    margin-top: 0;
    float: right;
  }
  .cmp_banner,
  .cmp_logo {
    height: 300px;
  }
  .cmp_devices {
    height: 400px;
  }
  .story_tile {
    width: 30%;
    padding-top: 30%;
  }
  .small_story {
    padding: 5.75%;
    margin: 1%;
    float: left;
  }
  .demo_bottom {
    padding-top: 10%;
  }
  .category_title,
  .demo_title,
  .category_description,
  .story_title {
    margin-left: 1%;
  }
  .story_title {
    bottom: 10px;
  }
  .solutions_right {
    top: -250px;
  }
  .solutions_right .smtitle {
    color: #fff;
  }
  .story_qr {
    width: 123px;
    height: 123px;
  }
  .story_qr img {
    width: 100px;
    height: 100px;
  }
  .demotop {
    height: 120px;
  }
  .demologo {
    width: 100px;
    height: 100px;
  }
  .demo_mapframe {
    height: 500px;
  }
  .place_logo {
    padding: 7%;
    top: -30px;
    border: 5px solid #fff;
  }
  .place_title_bar {
    padding: 14px 0 10px 2% !important;
  }
  .place_desc {
    padding: 5px 5% 5px 12%;
  }
  .search {
    top: 27px;
  }
  .screen_logo {
    padding: 14% 0;
  }
  .old_media {
    padding: 20px 30%;
  }
  .old_video,
  .old_audio,
  .old_text,
  .old_photo {
    width: 25%;
    padding-top: 25%;
  }
  .fifty {
    width: 50%;
  }
}
@media (min-width: 1025px) {
  .fronttop {
    height: 800px;
  }
  .frontcenter {
    width: 45%;
    margin-left: 5%;
    font-size: 60px;
    top: 300px;
  }
  .sitegrad {
    height: 600px;
  }
  .touchscreen {
    width: 624px;
    height: 924px;
    margin-right: 6%;
    top: 200px;
  }
  .catflip {
    width: 450px;
    height: 560px;
    margin-right: 2%;
    bottom: -160px;
  }
  .fronticons {
    width: 7%;
    height: 120px;
    padding: 20px;
  }
  #touchframe {
    width: 580px;
    height: 880px;
  }

  .title {
    font-size: 42px;
  }
  .frontsix {
    width: 40%;
    margin: 10px 30%;
  }
  .subpagetitle {
    font-size: 50px;
  }
  .frontfive .front50 {
    width: 43%;
  }
  .centerwide {
    width: 960px;
    left: -480px;
  }
  .cmp_devices {
    height: 550px;
  }
  .demo_bottom {
    padding-top: 15%;
  }
  .story_tile {
    width: 22%;
    padding-top: 22%;
  }
  .demotop {
    height: 150px;
  }
  .demologo {
    width: 130px;
    height: 130px;
  }
  .place_desc {
    padding: 5px 5% 5px 11%;
  }
  .place_logo {
    padding: 4%;
    top: -30px;
  }
  .screen_logo {
    padding: 10% 0;
  }
}
@media (min-width: 1260px) {
  .centerwide {
    width: 1160px;
    left: -580px;
  }
  .catflip {
    width: 500px;
    height: 760px;
    margin-right: 1%;
    bottom: -160px;
  }
}
@media (min-width: 1560px) {
  .catflip {
    width: 650px;
    height: 760px;
    margin-right: 1%;
    bottom: -160px;
  }
}

.hide {
  display: none;
}
#map .title {
}
::-webkit-input-placeholder {
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
}
:-moz-placeholder {
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
}
::-moz-placeholder {
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
}
:-ms-input-placeholder {
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
}

.mainbg {
  background-color: #06c;
}
.maincolor {
  color: #06c;
}
.mainborder {
  border: 1px solid #06c;
}
.secondbg {
  background-color: #39f;
}
.secondcolor {
  color: #39f;
}
.secondborder {
  border: 1px solid #39f;
}
/* .category_button {color:#06c} */
/* .category_button:hover {background-color:#06c} */
.storybottom {
  border-bottom: 3px solid #06c;
}
.category_title,
.demo_title,
.story_title,
.story_tile_title {
  border-bottom: 3px solid #06c;
}
