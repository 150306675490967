.adminbar {
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  z-index: 100;
  position: relative;
  color: #333;
}

.logo:hover {
  cursor: pointer;
  opacity: 0.8;
}

.loginForgotPasswordPageForm {
  box-shadow: none;
  height: 50px;
  background: #fff;
  position: relative;
  z-index: 2;
}

@media (min-width: 1px) {
  .adminbar {
    height: 70px;
  }
  .logo {
    width: 50px;
    height: 50px;
    background: url(./images/passitdown-logo.png) center center/contain
      no-repeat;
    float: left;
    position: relative;
    top: 10px;
    left: 10px;
  }
  .loginForgotPasswordPageForm {
    width: 80%;
    margin: 10px 10%;
  }
}

@media (min-width: 600px) {
  .logo {
    width: 200px;
    /* TODO: logo does not show text when placed over white background as is happening in the LoginPage */
    background: url(./images/logo-white.png) center center/contain no-repeat;
  }
}

@media (min-width: 860px) {
  .adminbar {
    height: 110px;
  }
  .logo {
    width: 260px;
    height: 80px;
    left: 20px;
    background: url(./images/logo-dark.png) center center/contain no-repeat;
  }
  .loginForgotPasswordPageForm {
    width: 60%;
    margin: 10px 20%;
  }
}

@media (min-width: 1025px) {
  .loginForgotPasswordPageForm {
    width: 70%;
    margin: 40px 15%;
    width: 40%;
    margin: 10px 30%;
  }
}
